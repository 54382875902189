<template>
    <section class="orderconfirmation">
        <IodReportPopup v-if="modalShow" :modalShow.sync="modalShow" :lensOrderId="lensOrderId" />
        <h3 class="h3 border-divider mb-4">
            {{
                t(
                    isConfirmingOrder
                        ? orderConfirmationTitle
                        : isConsignment
                        ? 'checkout_ConsignmentConsumptionDetails'
                        : 'checkout_OrderDetails'
                )
            }}
        </h3>
        <div v-if="orderConfirmations">
            <template>
                <div v-for="(order, index) in orderConfirmations" :key="order.orderNumber">
                    <order-display
                        :order="order"
                        :isConfirmingPreOrder="isConfirmingPreOrder"
                        :activeCustomer="activeCustomer"
                        :currentZone="currentZone"
                        :returnReasons="returnReasons"
                        :returnReasonId="returnReasonId"
                        :hasOrderingPermission="hasOrderingPermission"
                        :hasPricingPermission="hasPricingPermission"
                        :hasShipBillToPermission="hasShipBillToPermission"
                        :isDistributorSurgeonMode="isDistributorSurgeonMode"
                        :orderType="orderType"
                        @iod-download="iodDownload"
                        @show-complaint-reason-msg="showComplaintReasonMsg = $event"
                        @return-lens="returnLens"
                    />
                    <hr
                        v-if="index < orderConfirmations.length - 1"
                        class="double-hr"
                        :key="order.orderNumber"
                    />
                </div>
            </template>
        </div>

        <b-row v-if="!isDistributorSurgeonMode">
            <h5 class="mt-1 mb-1 font-weight-bold" v-html="t('orderDisclaimer')"></h5>
        </b-row>

        <b-overlay :show="showComplaintReasonMsg" no-wrap fixed>
            <template #overlay>
                <b-card footer-class="text-center">
                    <h3 class="font-weight-bold text-center">NOTICE</h3>
                    <p>
                        Complaints are not a valid return reason for this system. Please contact
                        Customer Support at
                        <a :href="'tel:' + currentBusinessUnit.customerServicePhone">{{
                            currentBusinessUnit.customerServicePhone
                        }}</a>
                        or send an email to
                        <a :href="'mailto:' + currentBusinessUnit.customerServiceEmail">{{
                            currentBusinessUnit.customerServiceEmail
                        }}</a>
                        with details of your complaint.
                    </p>
                    <div class="text-center">
                        <b-button variant="primary" @click="showComplaintReasonMsg = false">{{
                            t('close')
                        }}</b-button>
                    </div>
                </b-card>
            </template>
        </b-overlay>
    </section>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {ZoneCodes} from '@/constants/zone';
import OrderDisplay from '@/views/shoppingcart/components/OrderDisplay.vue';
import IodReportPopup from '@/views/iodreport/IodReportPopup.vue';
import {InventorySource} from '@/constants/inventory';
import {checkPermissions} from '@/utilities/permissions';
import get from 'lodash/get';
import first from 'lodash/first';
import {OracleOrderType} from '@/constants/order';

export default {
    components: {OrderDisplay, IodReportPopup},
    props: {
        orderId: {
            type: [Number, String],
            default: null,
        },
        defaultOrder: {
            type: Array,
            default: () => null,
        },
        isConfirmingOrder: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showComplaintReasonMsg: false,
            ZoneCodes,
            modalShow: false,
            lensOrderId: null,
            returnReasonId: null,
            returnReasons: [],
            orderConfirmations: this.defaultOrder,
        };
    },
    computed: {
        ...mapGetters('user', ['activeCustomer', 'currentBusinessUnit']),
        ...mapGetters('zone', ['currentZone']),
        hasOrderingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.ORDERING]: [this.PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [this.PERMISSIONS.DISTRIBUTOR_MODE]: [this.PERMISSIONS_VALUES.SURGEON],
            });
        },
        hasPricingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.PRICING]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
        hasShipBillToPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.SHIP_BILL_TO]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
        isConfirmingPreOrder() {
            return this.isConfirmingOrder && this.isDistributorSurgeonMode;
        },
        isConsignment() {
            return (
                this.orderConfirmations &&
                this.orderConfirmations.every((order) => {
                    const lensGroups = get(order, 'lensItemsByPatientId', []);
                    const lensItems = get(first(lensGroups), 'items', []);
                    const lens = first(lensItems);

                    return !!lens && lens.lensSourceId == InventorySource.CONSIGNMENT;
                })
            );
        },
        orderConfirmationTitle() {
            return this.isDistributorSurgeonMode
                ? 'checkout_PreOrderConfirmation'
                : 'checkout_OrderConfirmation';
        },
        thankYouText() {
            return this.isDistributorSurgeonMode
                ? 'checkout_ThankYouPreOrder'
                : 'checkout_ThankYou';
        },
        orderTotal() {
            const orders = this.orderConfirmations ?? [];

            return orders.reduce((orderAcc, order) => {
                const orderTotal = get(order, 'items', []).reduce(
                    (itemAcc, item) => itemAcc + item.price * item.quantity,
                    0
                );
                return orderAcc + orderTotal;
            }, 0);
        },
        orderSummary() {
            const orders = this.orderConfirmations ?? [];

            const summary = orders.reduce(
                (acc, order) => {
                    const freightCharge = get(order, 'shippingDetails.fixedFreightCharge', 0) || 0;
                    const itemsCount = get(order, 'items', []).reduce(
                        (itemAcc, item) => itemAcc + item.quantity,
                        0
                    );

                    acc.fixedFreightCharge += freightCharge;
                    acc.itemsCount += itemsCount;

                    return acc;
                },
                {
                    fixedFreightCharge: 0,
                    itemsCount: 0,
                }
            );

            return {
                isCartSummary: false,
                orderTotal: this.orderTotal,
                itemsCount: summary.itemsCount,
                balance: orders.reduce((acc, order) => acc + get(order, 'creditBalance', 0), 0),
                fixedFreightCharge: summary.fixedFreightCharge,
                ordersCount: orders.length,
            };
        },
        orderType() {
            const orders = this.orderConfirmations ?? [];
            if (orders.length > 0) {
                if (orders[0].orderSubStatus == 'Canceled') {
                    return OracleOrderType.CANCELED;
                }
                if (orders[0].isManualOrder) {
                    return OracleOrderType.MANUAL;
                }
            }

            return OracleOrderType.NORMAL;
        },
    },
    methods: {
        ...mapMutations('cart', ['setSummary']),
        ...mapMutations('orders', ['setOrderConfirmations']),
        ...mapActions('orders', ['getReturnReasons']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        async fetchOrderConfirmation(orderId) {
            try {
                const {success, results} = await this.blockingRequest('orders/fetch', orderId);
                if (success) {
                    this.orderConfirmations = [results];
                    this.setOrderConfirmations([results]);
                }
            } catch (error) {
                this.$router.push({
                    name: 'NotFound',
                });
            }
        },
        iodDownload(lens) {
            this.lensOrderId = lens.orderItemId.toString();
            this.modalShow = true;
        },
        onSelectReason(returnReasonId, props) {
            if (returnReasonId !== -1) return;
            this.showComplaintReasonMsg = true;
            this.returnReasonId = null;
        },
        async returnLens(orderId, lens) {
            try {
                this.setSpinner(true);
                const {success, error} = (
                    await this.$http.post(`orders/${orderId}/items/${lens.orderItemId}`, {
                        returnReasonId: this.returnReasonId,
                    })
                ).data;
                if (!success) throw error;
                await this.fetchOrderConfirmation(orderId);
                this.returnReasonId = null;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCodeDescription || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            } finally {
                this.setSpinner(false);
            }
        },
        async getResources() {
            this.returnReasons = (await this.getReturnReasons()).results || [];
        },
    },
    async created() {
        // defaultOrder is set when coming through the cart process.
        // If it is not set, but we have an orderId, we must be viewing order details from elsewhere.
        // In that case, fetch the order details.
        if (this.orderId && !this.defaultOrder) {
            if (isNaN(this.orderId)) {
                this.$router.push({name: 'NotFound'});
                return;
            }
            await this.fetchOrderConfirmation(this.orderId);
        } else if (!this.orderId && !this.defaultOrder) {
            // If there is no defaultOrder and no orderId, redirect
            this.$router.push({name: this.isDistributorSurgeonMode ? 'PreOrderList' : 'OrderList'});
            return;
        }
        this.setSummary(this.orderSummary);
        this.getResources();
    },
    destroyed() {
        this.setSummary(null);
        this.$emit('update:page-title', this.t('checkout_ShoppingCart'));
    },
    beforeMount() {
        this.$emit('update:page-title', this.isConfirmingOrder ? this.t(this.thankYouText) : null);
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/variables';

.border-divider {
    border-bottom: 3px solid #91979d;
    padding-bottom: 30px;
}
.double-hr {
    border: 0;
    border-top: 10px double #8c8b8b;
    height: 0;
    margin: 20px 0;
}
</style>
